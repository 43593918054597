import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { Helmet } from "react-helmet"
const PageRedirect = () => {
  useEffect(() => {
    navigate("/meeting-spaces/")
  }, [])
  return (
    <div>
      <Helmet>
        <meta
          http-equiv="refresh"
          content="0; url=https://gofloaters.com/meeting-spaces/"
        />
        <link rel="canonical" href="https://gofloaters.com/meeting-spaces/" />
      </Helmet>
    </div>
  )
}
export default PageRedirect
